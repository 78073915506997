import axios from 'axios';

import {
    CONTACT_FORM_SUBMIT_BEGIN,
    CONTACT_FORM_SUBMIT_SUCCESS,
    CONTACT_FORM_SUBMIT_ERROR,
} from '../types';


const contactFormSubmitBegin = () => ({
    type: CONTACT_FORM_SUBMIT_BEGIN
  });
  
  const contactFormSubmitSuccess = () => ({
    type: CONTACT_FORM_SUBMIT_SUCCESS
  });
  
  const contactFormSubmitError = () => ({
    type: CONTACT_FORM_SUBMIT_ERROR
  });

  export const submitContactForm = formData => async dispatch => {
    try {
      dispatch(contactFormSubmitBegin());
  
      const response = await axios.post('https://transferable-email-api.herokuapp.com/email', formData);
      dispatch(contactFormSubmitSuccess());
  
    } catch (err) {
      dispatch(contactFormSubmitError(err));
    }
  };
  