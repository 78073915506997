import React, { Component } from 'react';

// Components
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ContactForm from '../components/ContactForm';

// Styles
import './contact.styles.scss'

export default class ContactUsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
        <Layout currentPath="/contact">
            <SEO title="Contact Us" />
            <div className="contact-us-page">
                <section className="contact-us-page__body">
                    <div className="contact-us-page__body-wrapper">
                        <h1 className="contact-us-page__body-title">
                            How can we help?
                        </h1>
                        <p className="contact-us-page__body-paragraph">
                            Would you like to participate in our pilot? Have any general inquiries about Transferable?
                            Pleasefeel free to reach out below. We’ll be in touch with you shortly.
                        </p>
                        <ContactForm />
                    </div>
                </section>
            </div>
        </Layout>
    );
  }
}
