import { connect } from 'react-redux';
import { submitContactForm } from '../../state/actions/contactFormActions';

import ContactForm from './ContactForm';

const mapStateToProps = state => ({
    contactFormSubmittedSuccessfully: state.contactForm.contactFormSubmittedSuccessfully,
});

export default connect(mapStateToProps, {
    submitContactForm
})(ContactForm);