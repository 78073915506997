import React, { Component } from 'react';
import SubmittingSpinner from '../SubmittingSpinner';
import { Icon } from 'react-icons-kit';
import { ic_check } from 'react-icons-kit/md/ic_check';

import './ContactFormButton.styles.scss';

export default class ContactFormButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      success: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.submit = this.submit.bind(this);
    this.success = this.success.bind(this);
  }

  handleClick() {
    this.submit();
  }

  submit() {
    this.setState({
      submitting: true,
    }, () => {
      this.success();
    });
  }

  success() {
    setTimeout(() => {
      this.setState({
        success: true,
        submitting: false
      });
    }, 1500);
  }

  renderButtonContent() {
    const { submitting } = this.state;

    return (
      <div className="button-content">
        {
          submitting ? <SubmittingSpinner /> : 'Submit'
        }
      </div>
    );
  }

  renderSuccessContent() {
    return (
      <div className="success-content">
        <span>Submitted!</span>
        <Icon icon={ic_check} />
      </div>
    );
  }

  render() {
    const { showErrors } = this.props;

    const success = this.state.success ? 'success' : null;
    const submitting = this.state.submitting ? 'submitting' : null;

    const handleMouseDown = !this.state.formValid ? showErrors : null;

    return (
      <div className="contact-form-btn-container" onMouseDown={handleMouseDown}>
        <button
          className={`contact-form-submit-btn ${success}`}
          type="submit"
          disabled={!this.props.formValid}
          onClick={this.handleClick}
        >
          {
            this.state.success ?
              this.renderSuccessContent() :
                this.renderButtonContent()
          }
        </button>
      </div>
    );
  }
}
