import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import ContactFormButton from '../ContactFormButton';
import FormErrors from '../FormErrors';

import './ContactForm.styles.scss';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      email: '',
      message: '',
      formErrors: {
        name: ' is empty. Please enter your full name.',
        email: ' is invalid. Please enter a valid email address.',
        message: ' is empty. How can we help?'
      },
      fullNameValid: false,
      emailValid: false,
      messageValid: false,
      formValid: false,
      errorsVisible: false
    };

    this.handleFullName = this.handleFullName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.showErrorsOnFailedSubmit = this.showErrorsOnFailedSubmit.bind(this);
  }

  handleFormSubmit(e) {
    const { submitContactForm } = this.props;
    const { fullName, email, message } = this.state;
    const subject = 'Contact Form';
    e.preventDefault();

    submitContactForm({
      subject,
      fullName,
      email,
      message
    });
  }

  handleFullName(e) {
    const { name, value } = e.target;

    this.setState({
      fullName: value,
      errorsVisible: false
    }, () => {
      this.validateField(name, value);
    });
  }

  handleEmail(e) {
    const { name, value } = e.target;

    this.setState({
      email: value,
      errorsVisible: false
    }, () => {
      this.validateField(name, value);
    });
  }

  handleMessage(e) {
    const { name, value } = e.target;

    this.setState({
      message: value,
      errorsVisible: false
    }, () => {
      this.validateField(name, value);
    });
  }

  validateField(fieldName, value) {
    let {
      formErrors: fieldValidationErrors,
      fullNameValid,
      emailValid,
      messageValid
    } = this.state;

    switch (fieldName) {
      case 'full-name':
        fullNameValid = value.length > 0;
        fieldValidationErrors.name = fullNameValid ? '' : ' is empty. Please enter your full name.';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
        fieldValidationErrors.email = emailValid ? '' : ' is invalid. Please enter a valid email address.';
        break;
      case 'message':
        messageValid = value.length > 0;
        fieldValidationErrors.message = messageValid ? '' : ' is empty. What are your plans?';
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      fullNameValid,
      emailValid,
      messageValid
    }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    const { fullNameValid, emailValid, messageValid, formValid } = this.state;

    this.setState({
      formValid: fullNameValid && emailValid && messageValid
    });
  }

  showErrorsOnFailedSubmit() {
    this.setState({
      errorsVisible: true
    });
  }

  renderContactForm() {
    return(
      <form
        id="contact-form"
        className="contact-form"
        autoComplete="off"
        onSubmit={this.handleFormSubmit}
      >
        <span className="contact-form__row">
          <input
            className="contact-form__name input-field"
            type="text"
            name="full-name"
            placeholder="Full name"
            onChange={this.handleFullName}
          />
        </span>

        <span className="contact-form__row">
          <input
            className="contact-form__row input-field"
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.handleEmail}
          />
        </span>

        <span className="contact-form__row text-area">
          <textarea
            name="message"
            className="contact-form__message"
            placeholder="What's your message?"
            onChange={this.handleMessage}
          />
        </span>

        <ContactFormButton
          formValid={this.state.formValid}
          showErrors={this.showErrorsOnFailedSubmit}
        />

        <div className="contact-form__errors-panel">
          <FormErrors formErrors={this.state.formErrors} errorsVisible={this.state.errorsVisible} />
        </div>
      </form>
    );
  }

  renderThankYouMessage() {
    return (
      <Fade clear>
        <div className="contact-form__submitted-message-container">
          <span>
            {'Thanks for your interest! '}
          </span>


          <span style={{ color: 'rgba(255, 140, 0, 1)' }}>
            {'We\'ll be in touch soon.'}
          </span>
        </div>
      </Fade>
    );
  }

  render() {
    const { contactFormSubmittedSuccessfully } = this.props;
    const { errorsVisible } = this.state;
    const submittedSuccessfully = contactFormSubmittedSuccessfully ? 'submittedSuccessfully' : null;
    const shouldRenderWithErrors = errorsVisible ? 'with-errors' : null;

    return (
      <div className={`contact-form-container ${submittedSuccessfully} ${shouldRenderWithErrors}`}>
        {
          contactFormSubmittedSuccessfully ?
            this.renderThankYouMessage() :
              this.renderContactForm()
        }
      </div>
    );
  }
}
